<template>
  <multi-step-window :steps="steps" :currentStep="step">
    <template #step1>
      <img class="mx-auto mb-3" src="@main/assets/img/gif/loader.gif" alt="" />
      <p>Процесс проверки аккаунта занимает не более 5 минут. Пожалуйста, подождите.</p>
    </template>
    <template #step2>
      <div class="d-flex flex-column align-items-center justify-content-between">
        <img class="mx-auto mb-3" src="@main/assets/img/svg/done_icon.svg" alt="" />
        <h2 class="mb-4 fs-20 text-center">
          Ваш ник в отзывах и карточке изменен на {{ request.new_instaname }}
        </h2>
        <b-button variant="outline-default" class="ph-42 px-4" :to="{ name: 'reviews' }"
          >Перейти к отзывам</b-button
        >
      </div>
    </template>
  </multi-step-window>
</template>

<script>
import bloggers from '@main/api/bloggers';
import MultiStepWindow from '../reusable/windows/MultiStepWindow.vue';

export default {
  components: { MultiStepWindow },
  props: {
    request: Object,
  },
  data: () => ({
    step: 1,
    steps: 2,
  }),
  methods: {
    async changeInstaname() {
      await bloggers.changeBloggerInstaname(this.request);
      this.step = 2;
      this.$emit('success');
    },
  },
  async mounted() {
    await this.changeInstaname();
  },
};
</script>
