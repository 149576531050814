<template>
  <div>
    <div class="hovering__pad p-3 mx-auto w-100" style="max-width: 400px !important">
      <div>
        <template v-if="!success">
          <h1 class="mb-3">Сервис по замене ника</h1>
          <p class="mb-4">Позволяет заменить ник в отзывах и карточке блогера.</p>
        </template>
        <multi-step-window :steps="steps" :currentStep="step"
          ><template #step1>
            <b-form-group label="Ваш старый ник"
              ><b-form-input
                :class="{ error: $v.request.old_instaname.$anyError || !!error.length }"
                class="h-42"
                placeholder="Введите старый ник"
                v-model="$v.request.old_instaname.$model"
              ></b-form-input
            ></b-form-group>
            <p v-if="error" class="fs-12" style="color: red">{{ error }}</p>
            <b-form-group class="my-4" label="Ваш новый ник"
              ><b-form-input
                :class="{ error: $v.request.new_instaname.$anyError }"
                class="h-42"
                placeholder="Введите новый ник"
                v-model="$v.request.new_instaname.$model"
              ></b-form-input
            ></b-form-group>
            <div v-if="error" style="background-color: #fffce3" class="p-2">
              Если вы хотите заменить ник на абсолютно новый недавно созданный аккаунт, то к
              сожалению, это невозможно на нашем сервисе
            </div>

            <div class="d-flex align-items-center justify-content-between mt-4">
              <b-button
                @click="$router.go(-1)"
                style="min-width: 120px"
                variant="outline-default"
                class="ph-42"
                >Назад</b-button
              >
              <b-button
                @click="initializeRequest"
                style="min-width: 120px"
                variant="primary"
                class="ph-42"
                >Далее</b-button
              >
            </div> </template
          ><template #step2>
            <p class="my-4 fs-16">Ваш старый ник: {{ request.old_instaname }}</p>
            <p class="my-4 fs-16">Ваш новый ник: {{ request.new_instaname }}</p>
            <h5 class="fw-500 mt-5 mb-3">Произвести замену?</h5>
            <div class="d-flex justify-content-between">
              <b-button
                @click="goLeft"
                style="min-width: 120px"
                variant="outline-default"
                class="ph-42"
                >Нет</b-button
              >
              <b-button @click="step = 3" style="min-width: 120px" variant="yellow" class="ph-42"
                >Да</b-button
              >
            </div> </template
          ><template #step3
            ><multi-step-window :steps="strategies" :currentStep="strategy" :switching="true"
              ><template #manual>
                <manual-instaname-change
                  @no="step = 2"
                  @success="success = true"
                  :request="request"
                ></manual-instaname-change>
              </template>
              <template #auto
                ><automatic-instaname-change
                  @success="success = true"
                  :request="request"
                ></automatic-instaname-change></template></multi-step-window></template
        ></multi-step-window>
      </div>
    </div>
  </div>
</template>

<script>
import bloggers from '@main/api/bloggers';
import { mapMutations, mapActions } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import MultiStepWindow from '../reusable/windows/MultiStepWindow.vue';
import AutomaticInstanameChange from './AutomaticInstanameChange.vue';
import ManualInstanameChange from './ManualInstanameChange.vue';

export default {
  components: { MultiStepWindow, AutomaticInstanameChange, ManualInstanameChange },
  validations: {
    request: {
      old_instaname: { required, minLength: minLength(3) },
      new_instaname: { required, minLength: minLength(3) },
    },
  },
  data: () => ({
    steps: 3,
    step: 1,
    strategy: 'auto',
    strategies: ['manual', 'auto'],
    success: false,
    request: {
      new_instaname: '',
      old_instaname: '',
    },
    error: '',
  }),
  methods: {
    ...mapActions('notifications', ['setWindow']),
    goLeft() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    async checkChangeAvailable() {
      try {
        const resp = await bloggers.changeBloggerInstaname(this.request, { check: true });
        return resp;
      } catch (e) {
        if (e.response.status === 403) {
          if (e.response.data.errcode === 1) {
            this.strategy = 'manual';
            this.step = 2;
            return;
          }
          if (e.response.data.errcode === 10) {
            this.setWindow({
              text: 'Замена ника доступна только в подписке',
              buttonClass: 'ph-42',
              buttons: [
                {
                  text: 'Назад',
                  attrs: { variant: 'outline-default', class: 'ph-42' },
                  handler: () => {
                    this.setWindow(null);
                  },
                },
                {
                  text: 'Оплатить подписку',
                  attrs: { variant: 'yellow', class: 'ph-42' },
                  handler: () => {
                    window.location.href = 'https://sub.easyprbot.com';
                  },
                },
              ],
            });
            return;
          }
          this.error = e.response.data.detail;
        }
      }
    },
    async initializeRequest() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.error = 'Введите оба ника!';
        return;
      }
      const initialResp = await this.checkChangeAvailable();
      if (initialResp && initialResp.success) {
        this.step = 2;
      }
    },
  },
  watch: {
    'request.new_instaname': {
      handler(val) {
        this.error = '';
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
