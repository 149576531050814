<template>
  <multi-step-window :steps="steps" :currentStep="step">
    <template #step1>
      <p class="fs-16 mb-2">Ваш старый ник: {{ request.old_instaname }}</p>
      <div style="background-color: #fffce3" class="p-2 mb-4">
        Мы не нашли соответствие в нашей базе. Чтобы изменить ник нам нужно удостовериться, что это
        именно ваш аккаунт 👇
      </div>
      <div class="step-bullet mb-3" :bullet-value="1">
        Зайдите на свою страницу с другого аккаунта и посмотрите есть ли Информация об аккаунте.
        Только в этом случае мы сможем изменить ник.
      </div>
      <div style="background-color: #f8f8f8; border-radius: 10px" class="p-2 mb-4">
        Информация об аккаунте - данные об аккаунте, которые позволяют проверить достоверность
        аккаунта. В него включены: дата регистрации, страна, реклама, предыдущие имена пользователя
        и аккаунты с общими подписчиками. <br /><br />
        Функция есть у пользователей с: высокими охватами и активностью аудитории большим
        количеством подписчиков подтвержденной медийностью/популярностью
      </div>
      <div class="step-bullet mb-4" :bullet-value="2">
        Для замены ника у вас должна быть оплачена подписка.
      </div>
      <div class="step-bullet mb-4" :bullet-value="3">
        Если нет Информации об аккаунте или данные в аккаунте отличаются от тех, что вы указали, то
        замена будет невозможна, и деньги за подписку в этом случае не возвращаются
      </div>
      <b-form-checkbox
        :class="{ error: $v.agree.$anyError }"
        size="xlg"
        v-model="$v.agree.$model"
        style="font-size: 14px !important; line-height: 16.59px"
        >У меня есть функция Информация об аккаунте и указанные мной данные совпадают с ней. Я
        согласен с правилами указанными выше.</b-form-checkbox
      >
      <div class="d-flex justify-content-between mt-5">
        <b-button
          @click="$emit('no')"
          style="min-width: 60px"
          class="ph-42"
          variant="outline-default"
          >Нет</b-button
        >
        <b-button
          @click="sendInstanameChangeRequest"
          style="min-width: 60px"
          class="ph-42"
          variant="yellow"
          >Да, отправить на модерацию</b-button
        >
      </div>
    </template>
    <template #step2>
      <div class="d-flex flex-column align-items-center justify-content-between">
        <img class="mx-auto mb-3" src="@main/assets/img/svg/done_icon.svg" alt="" />
        <h2 class="fs-20 text-center">Данные о замене ника отправлены на модерацию</h2>
        <p class="text-center my-3">
          Проверка обычно занимает 1-3 дня. Результаты проверки будут отправлены вам на почту.
        </p>
        <b-button variant="outline-default" class="ph-42 px-4 mt-2" :to="{ name: 'reviews' }"
          >Перейти к отзывам</b-button
        >
      </div>
    </template>
  </multi-step-window>
</template>

<script>
import bloggers from '@main/api/bloggers';
import MultiStepWindow from '../reusable/windows/MultiStepWindow.vue';

export default {
  components: { MultiStepWindow },
  data: () => ({
    step: 1,
    steps: 2,
    agree: false,
  }),
  validations: {
    agree: {
      required: (val) => val !== false,
    },
  },
  props: {
    request: Object,
  },
  methods: {
    async sendInstanameChangeRequest() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      await bloggers.changeInstanameRequest.create(this.request);
      this.step = 2;
      this.$emit('success');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .custom-control .custom-checkbox .b-custom-control-xlg .error {
  border: none !important;
}
</style>
